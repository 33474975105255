
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const STRIPE_TABLE_ID = process.env.REACT_APP_STRIPE_TABLE_ID;
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const TRIGGER_PAGES_FORMAT = [{ url: '', type: 'include', rule: 'exact', id: 1 }];
export const SURVEY_PAYLOAD = {
    name: '',
    title: '',
    description: '',
    template_type: '',
    genre: '',
    cta_image: '',
    cta_text: '',
    cta_url: '',
    rating_type: '',
    active: true,
    type: '',
    created_at: '',
    question_arr: [{}],
    trigger: {
        entry_method: 'immediately',
        delayed_time: 0,
        show_on_all_pages: true,
        pages: TRIGGER_PAGES_FORMAT,
        has_user_answered: false
    }
};

export const feedbackStatus = [
    { id: 'all', name: 'All' },
    { id: 'pending', name: 'Pending' },
    { id: 'open', name: 'Open' },
    { id: 'planned', name: 'Planned' },
    { id: 'progress', name: 'In progress' },
    { id: 'complete', name: 'Complete' },
    { id: 'trash', name: 'Trash' },
];

export const TEMPLATE_QUESTION_MAP = {
    'stars': 'Was the onboarding process engaging and interactive?',
    'emoji': 'How would you rate the onboarding experience?',
    'numbers': 'How would you rate the onboarding experience?',
    'manual': 'How satisfied are you with our product?'
};
export const CTA_TEXT_MAP = {
    'stars': 'Submit',
    'emoji': 'Submit',
    'numbers': 'Submit',
    'nudge': 'Learn More'
};

export const PRICING_PLANS = [
    'pl_pro', 'pl_business', 'pl_enterprise', 'pl_agency'
];
export const NOTION_AUTH_URL = process.env.REACT_APP_NOTION_AUTH_URL;

export const FORBIDDEN_KEYWORDS = [
    'test',
    'example',
    'admin',
    'user',
    'staging',
    'tools',
    'free',
    'docs',
    'jarvis',
];
export const ITEMS_PER_PAGE = 5;
export const USERS_ITEMS_PER_PAGE = 12;
export const ITEMS_PER_PAGE_XL = 6;
export const XL_WIDTH = 1600;
export const CHANGELOG_STATUSES = [
    {
        name: 'All',
        id: 'all',
    },
    {
        name: 'Draft',
        id: 'draft',
    },
    {
        name: 'Published',
        id: 'published',
    }
];
export const SURVEY_STATUSES = [
    {
        name: 'All',
        id: 'all',
    },
    {
        name: 'Active',
        id: 'active',
    },
    {
        name: 'Inactive',
        id: 'inactive',
    },
];

export const LANGUAGES = [
    {
        title: 'English',
        value: 'en'
    },
    {
        title: 'Spanish',
        value: 'es'
    },
    {
        title: 'Danish',
        value: 'da'
    }
];

export const TOOLS_ROUTE_MAPPING = {
    '/tools': 'Productlogz - Tools',
    '/tools/free-cac-calculator': 'Productlogz - CAC Calculator'
};
