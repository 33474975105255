import { BASE_URL } from "./constants";
import axios from "axios";
import jwt_decode from "jwt-decode";
import {
    setPlCookie,
    getCookie
} from './Utils';

// TODO: add application/json inside post req.

const client = axios.create({
    baseURL: BASE_URL,
    headers: {},
});


const requestHandler = async (request) => {
    let authToken;
    const tokenExists = getCookie('pl.authToken');
    const refreshTokenExists = getCookie('pl.refreshToken');
    const activeDomain = window.location.hostname.split('.')[1];
    const activeSpace = window.location.host.split('.')[0];

    // console.log('tokenExists---===', tokenExists);

    if (tokenExists && tokenExists !== "undefined") {
        // console.log('tokenExists---===', tokenExists);
        authToken = tokenExists;
        const accessToken = jwt_decode(authToken);
        const currentTime = Math.round(Date.now() / 1000);

        if (accessToken.exp > currentTime) {
            request.headers.Authorization = `Bearer ${authToken}`;
            request.headers.domain = activeSpace;
        } else if (refreshTokenExists && refreshTokenExists !== "undefined") {
            const refreshToken = refreshTokenExists;
            const refreshTokenExp = jwt_decode(refreshToken).exp;

            if (refreshTokenExp > currentTime) {
                try {
                    const response = await axios.post(`${BASE_URL}api/refreshtoken`, {}, {
                        headers: {
                            Authorization: `Bearer ${refreshToken}`,
                            'domain': activeSpace,
                        }
                    });
                    setPlCookie('pl.authToken', response.data.accessToken, 30, activeDomain);
                    request.headers.Authorization = `Bearer ${response.data.accessToken}`;
                    request.headers.domain = activeSpace;
                }
                catch (err) {
                    // console.log(`This block should catch any instantiation errors.`);
                    window.location = '/login';
                }

            }
        }
    } else { // if localstorage doesnt have access token.
        // var config = {
        //     method: 'get',
        //     url: `${BASE_URL}api/auth/token`,
        // };

        // await axios(config)
        //     .then(function (response) {
        //         const tokenResp = response.data['data'];

        //         localStorage.setItem('authToken', JSON.stringify(tokenResp.token));
        //         request.headers.Authorization = `Bearer ${tokenResp.token}`;

        //         return request;
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    }
    return request;
};

const responseHandler = response => response;

const errorHandler = error => {
    if (error.response?.status === 401) {
        // TODO:
        // 1. add silent refresh token logic.
        // 2. increase token expiry time.

        // this will redirect to llgin page.
        window.location = '/login';
    }

    if (!error.response) {
        // for 5xx errors..
        // TODO:: create a component for 5xx errors.
        // window.location = '/login';
    }
    return Promise.reject(error);
};

client.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

client.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);


export default client;