import i18n from "i18next";
import enTranslation from "./assets/languages/en.json";
import esTranslation from "./assets/languages/es.json";
import daTranslation from "./assets/languages/da.json";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

import client from './components/common/api';

// Function to fetch user language preference from API
const getUserLanguage = async () => {
	const activeSpace = window.location.host.split('.')[0];
	try {
		const response = await client.get(`/workspace/get/language?activeSpaceName=${activeSpace}`);
		const data = await response.data;
		if (data.success) {
			return data.data.code;
		}
		return 'en';
	} catch (error) {
		// Check specifically for 401 unauthorized error
		if (error.response && error.response.status === 401) {
			console.log('User not authenticated, defaulting to English');
			return 'en';
		}
		console.error('Failed to fetch user language:', error);
		return 'en';
	}
};

// Initialize i18n with async language detection
const initI18n = async () => {
	try {
		const userLanguage = await getUserLanguage();

		i18n
			.use(Backend)
			.use(initReactI18next)
			.init({
				resources: {
					en: { translation: enTranslation },
					es: { translation: esTranslation },
					da: { translation: daTranslation }
				},
				lng: userLanguage,
				fallbackLng: "en",
				interpolation: { escapeValue: false }
			});
	} catch (error) {
		// If initialization fails, set up with default English
		console.error('Failed to initialize i18n:', error);
		i18n
			.use(Backend)
			.use(initReactI18next)
			.init({
				resources: {
					en: { translation: enTranslation },
					es: { translation: esTranslation },
					da: { translation: daTranslation }
				},
				lng: 'en',
				fallbackLng: "en",
				interpolation: { escapeValue: false }
			});
	}
};

// Initialize once and prevent re-initialization
let initialized = false;
if (!initialized) {
	initialized = true;
	initI18n();
}

export default i18n;




