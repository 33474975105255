import client from './api';


export const isValidEmailAddress = (address) => (!!address.match(/^\S+@\S+\.\S+$/));
const invalidNames = ['test', 'docs', 'productlogz', 'product', 'staging', 'prod', 'dev', 'api', 'app', 'dashboard', 'admin', 'support', 'help'];


export const allChangelogTags = [
    { name: 'New', id: 'new', color: '#2dce89' },
    { name: 'Improvement', id: 'improvement', color: '#11cdef' },
    { name: 'Fixes', id: 'fixes', color: '#5e72e4' },
];

export const ADD_EDIT_ROLES = ['manager', 'admin'];

export const uploadImageToServer = (imageFile, activeSpace, module) => {
    const create_payload = new FormData();
    create_payload.append('file', imageFile);
    create_payload.append('activeSpace', activeSpace);
    create_payload.append('module', module);

    return new Promise((resolve, reject) => {
        client.post('/api/image-upload', create_payload).then(response => {
            if (response.data.success) {
                resolve({ success: true, data: response.data.data });
            }
            resolve({ success: false, data: {} });

        }).catch(error => {
            console.log('error---', error);
            reject(error);
        });
    });
}

export const createFormPayload = (changelogPayload, status, activeSpace) => {
    const create_payload = new FormData();
    create_payload.append('title', changelogPayload.title.trim());
    create_payload.append('content', changelogPayload.content);
    create_payload.append('file', changelogPayload.cover_image.value);
    create_payload.append('subtitle', changelogPayload.subtitle.value);
    create_payload.append('fontSize', changelogPayload.fontSize);

    create_payload.append('show_reactions', changelogPayload.reactions.show);
    create_payload.append('show_author', changelogPayload.author.show);
    create_payload.append('show_cover_image', changelogPayload.cover_image.show);
    create_payload.append('show_subtitle', changelogPayload.subtitle.show);
    create_payload.append('status', status);
    create_payload.append('activeSpaceName', activeSpace);

    return create_payload;
};

export const StatusValues = [
    { id: 'open', name: 'Open' },
    { id: 'planned', name: 'Planned' },
    { id: 'progress', name: 'In progress' },
    { id: 'complete', name: 'Complete' },
    { id: 'trash', name: 'Trash' },
];

export const BusinessValues = [
    { id: 'sass', name: 'Saas' },
    { id: 'creator', name: 'Creator' },
    { id: 'ecomerce', name: 'Ecomerce' },
    { id: 'freelance', name: 'Freelance or Consultant' },
    { id: 'agency', name: 'Agency' },
    { id: 'other', name: 'Other' },
];

export const referredByValues = [
    { id: 'google', name: 'Google' },
    { id: 'dealmirror', name: 'Deal Mirror' },
    { id: 'linkedin', name: 'LinkedIn' },
    { id: 'twitter', name: 'Twitter' },
    { id: 'facebook', name: 'Facebook' },
    { id: 'appsumo', name: 'Appsumo' },
    { id: 'producthunt', name: 'Producthunt' },
    { id: 'indiehackers', name: 'Indiehackers' },
    { id: 'other', name: 'Other' },
];

export const generateRandomEmail = (length = 10, domain = "pl.com") => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomUsername = '';
    for (let i = 0; i < length; i++) {
        randomUsername += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return `${randomUsername}@${domain}`;
};

export const setPlCookie = (cname, cvalue, exdays, domain) => {
    if (!cvalue || cvalue === 'undefined') return;
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = `expires= ${d.toUTCString()}`;
    let cookieString = `${cname}=${cvalue};${expires};path=/;`;
    if (!domain.includes('localhost')) {
        cookieString += `domain=.${domain}.com;`;
    }
    document.cookie = cookieString;
};

// https://www.w3schools.com/js/js_cookies.asp#:%7E:text=a%20cookie%20value-,A%20Function%20to%20Set%20a%20Cookie,-First%2C%20we%20create
export const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const getNotionMappedInfo = (mappedInfo) => {
    const statusFields = [
        'status_key_mapping',
        'open_status',
        'planned_status',
        'pending_status',
        'progress_status',
        'complete_status',
        'trash_status'
    ];

    return statusFields
        .map(field => ({
            id: field,
            name: field.replace(/_status|_key_mapping/g, ''),
            targetValue: mappedInfo[field]
        }));
};

export const changeLogValidations = (payload) => {
    const errors = {
        message: '',
        isValid: true
    };
    if (!payload.title) {
        errors.message = 'Title is required';
        errors.isValid = false;
    } else if (payload.title.length < 4) {
        errors.message = 'Title cannot be less than 4 characters';
        errors.isValid = false;
    }
    else if (!payload.content) {
        errors.message = 'Content is required';
        errors.isValid = false;
    } else if (payload.cover_image.show && !payload.cover_image.value) {
        errors.message = 'Cover image is missing';
        errors.isValid = false;
    } else if (payload.subtitle.show && !payload.subtitle.value) {
        errors.message = 'Subtitle is missing';
        errors.isValid = false;
    }
    return errors;
};

export const changeLogDraftValidations = (payload) => {
    const errors = {
        message: '',
        isValid: true
    };
    if (!payload.title) {
        errors.message = 'Title is required';
        errors.isValid = false;
    } else if (payload.title.length < 4) {
        errors.message = 'Title cannot be less than 4 characters';
        errors.isValid = false;
    }
    else if (!payload.content) {
        errors.message = 'Content is required';
        errors.isValid = false;
    }
    return errors;
};

const blockedNmaes = ['test', 'docs', 'productlogz', 'product', 'john', 'doe', 'me', 'staging', 'prod', 'dev', 'api', 'app', 'dashboard', 'admin', 'support', 'help', 'name'];

const stepOneValidation = (values) => {
    const errors = {
        message: '',
        isValid: true
    };
    if (!values.name) {
        errors.message = 'Name is required';
        errors.isValid = false;
    } else if (values.name.trim().length < 4) {
        errors.message = 'Name cannot be less than 4 characters';
        errors.isValid = false;
    } else if (blockedNmaes.includes(values.name.toLowerCase())) {
        errors.message = 'Name cannot be used';
        errors.isValid = false;
    }
    else if (!values.agreeTerms) {
        errors.message = "Kindly accept terms & conditions";
        errors.isValid = false;
    }
    else if (!values.email) {
        errors.message = 'Email is required';
        errors.isValid = false;
    } else if (!isValidEmailAddress(values.email)) {
        errors.message = 'Invalid email address';
        errors.isValid = false;
    }
    else if (!values.password) {
        errors.message = 'Password is required';
        errors.isValid = false;
    }
    return errors;
}

const stepTwoValidation = (values) => {
    const { company, subdomain } = values;
    const errors = {
        message: '',
        isValid: true
    };
    if (!company) {
        errors.message = 'Company name is required';
        errors.isValid = false;
    } else if (company.length < 4) {
        errors.message = 'Company name cannot be less than 4 characters';
        errors.isValid = false;
    } else if (company.length > 25) {
        errors.message = 'Company name cannot be grater than 25 characters';
        errors.isValid = false;
    }
    else if (blockedNmaes.includes(company.toLowerCase())) {
        errors.message = 'Invalid company name. Please use another name';
        errors.isValid = false;
    }
    else if (invalidNames.includes(subdomain)) {
        errors.message = 'Invalid Company name. Please choose another name';
        errors.isValid = false;
    }
    else if (!subdomain) {
        errors.message = 'Subdomain is required';
        errors.isValid = false;
    }
    return errors;
};

const stepThreeValidation = (values) => {
    const { industry, referred_by } = values;
    const errors = {
        message: '',
        isValid: true
    };
    if (!industry || !referred_by) {
        errors.message = 'Please complete all the fields.';
        errors.isValid = false;
    }
    return errors;
};

const stepFourValidation = (payload) => {
    const errors = {
        message: '',
        isValid: true
    };
    if (!payload.current_feature_title || !payload.current_feature_description) {
        errors.message = 'Please complete all the fields.';
        errors.isValid = false;
    }
    return errors;
};

export const onboardingValidation = (payload, step) => {
    let val;
    if (step === 'one') {
        val = stepOneValidation(payload);
    } else if (step === 'two') {
        val = stepTwoValidation(payload);
    } else if (step === 'three') {
        val = stepThreeValidation(payload);
    } else if (step === 'four') {
        val = stepFourValidation(payload);
    }
    return val;
};

export const getRoleFromWorkspace = (roles, w_space_name) => {
    let _role = {};
    roles.map((role) => {
        if (role.workspace === w_space_name) {
            _role = role;
        }
    });
    return _role;
};

const surveyStepTwoValidation = (payload) => {
    const { rating_type, question_arr, cta_text, cta_url } = payload;
    const errors = {
        message: '',
        isValid: true
    };
    if (rating_type === 'stars') {
        if (question_arr.length < 1) {
            errors.message = 'Please add at the question';
            errors.isValid = false;
        } else if (question_arr[0].value.trim().length === 0) {
            errors.message = 'Please add the question';
            errors.isValid = false;
        }
    } else if (rating_type === 'emoji') {
        if (question_arr.length < 1) {
            errors.message = 'Please add the question';
            errors.isValid = false;
        } else if (question_arr[0].value.trim().length === 0) {
            errors.message = 'Please add the question';
            errors.isValid = false;
        }
    } else if (rating_type === 'text') {
        if (question_arr.length < 1) {
            errors.message = 'Please add the question';
            errors.isValid = false;
        }
    } else if (rating_type === 'nudge') {
        if (!cta_text) {
            errors.message = 'Please add CTA text';
            errors.isValid = false;
        } else if (!cta_url) {
            errors.message = 'Please add CTA link';
            errors.isValid = false;
        }
    }
    return errors;
};

const surveyStepThreeValidation = (surveyPayload) => {
    const errors = {
        message: '',
        isValid: true
    };
    const {
        trigger,
    } = surveyPayload;

    if (!trigger.show_on_all_pages) {
        if (trigger.pages.length === 0) {
            errors.message = 'Please add page url';
            errors.isValid = false;
        }
        trigger.pages.forEach((page) => {
            if (page.url.trim().length === 0) {
                errors.message = 'Please add page url';
                errors.isValid = false;
            }
        })

    }
    if (trigger.entry_method === 'delay') {
        if (trigger.delayed_time === 0) {
            errors.message = 'Please enter delay';
            errors.isValid = false;
        }
    }
    return errors;
};

export const surveyValidation = (payload, step) => {
    let val = {
        message: '',
        isValid: true
    };
    if (step === 2) {
        val = surveyStepTwoValidation(payload);
    } else if (step === 3) {
        val = surveyStepThreeValidation(payload);
    } else if (step === 4) {
        // val = surveyStepFourValidation(payload);
    }
    return val;
};

// https://www.svgrepo.com/svg/424224/laugh-laugh-face-laught-emoji?edit=true
export const PlFaceDizzy = ({ strokeWidth }) => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="#fd2236">
    <circle cx="50" cy="50" r="45" fill="none" stroke="#fd2236" strokeWidth={strokeWidth} />
    <line x1="30" y1="30" x2="45" y2="45" stroke="#fd2236" strokeWidth={strokeWidth} strokeLinecap="round" />
    <line x1="45" y1="30" x2="30" y2="45" stroke="#fd2236" strokeWidth={strokeWidth} strokeLinecap="round" />
    <line x1="55" y1="30" x2="70" y2="45" stroke="#fd2236" strokeWidth={strokeWidth} strokeLinecap="round" />
    <line x1="70" y1="30" x2="55" y2="45" stroke="#fd2236" strokeWidth={strokeWidth} strokeLinecap="round" />
    <circle cx="50" cy="70" r="10" fill="none" stroke="#fd2236" strokeWidth={strokeWidth} />
</svg>
);

export const PlFaceFrown = ({ strokeWidth }) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#f67611" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="45" fill="none" stroke="#f67611" strokeWidth={strokeWidth} />
        <circle cx="35" cy="40" r="3" fill="#f67611" />
        <circle cx="65" cy="40" r="3" fill="#f67611" />
        <path d="M30 70 Q50 60 70 70" fill="none" stroke="#f67611" strokeWidth="4" strokeLinecap="round" />
    </svg>
);

export const PlFaceMeh = ({ strokeWidth }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="#f0af1f">
        <circle cx="50" cy="50" r="45" fill="none" stroke="#f0af1f" strokeWidth={strokeWidth} />
        <circle cx="35" cy="40" r="3" fill="#f0af1f" />
        <circle cx="65" cy="40" r="3" fill="#f0af1f" />
        <line x1="35" y1="70" x2="65" y2="70" stroke="#f0af1f" strokeWidth="4" strokeLinecap="round" />
    </svg>
);

export const PlFaceSmile = ({ strokeWidth }) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#08c050" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="45" fill="none" stroke="#08c050" strokeWidth={strokeWidth} />
        <circle cx="35" cy="40" r="3" fill="#08c050" />
        <circle cx="65" cy="40" r="3" fill="#08c050" />
        <path d="M30 65 Q50 80 70 65" fill="none" stroke="#08c050" strokeWidth="4" strokeLinecap="round" />
    </svg>
);

export const PlFaceLaugh = ({ strokeWidth }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" stroke="#179a56" fill="none" strokeWidth={strokeWidth}>
        <circle cx="256" cy="256" r="238" />
        <path d="M130.7 313.9c-4.2-13.6 7.1-25.9 21.3-25.9l212.5 0c14.2 0 25.5 12.4 21.3 25.9C369 368.4 318.2 408 258.2 408s-110.8-39.6-127.5-94.1zM144.4 192a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
    </svg>
);

// smiley svg ends